<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		
		<h2>「Whistle」ユーザー サービス契約</h2>
		
		<p>「Whistle」アプリケーション ソフトウェアおよび関連サービスのご利用へようこそ! <br>
		より良いサービスをご提供するために、「Whistle」ユーザーサービス規約（以下「本規約」といいます）をよくお読みください。 「Whistle」ソフトウェアおよび関連サービスのご利用を開始する前に、本規約、特に責任の免除または制限、権利使用許諾および情報利用規約、および特別個人のアクティベーションおよび使用に関する規約をよくお読みいただき、十分理解してください。サービス、規約、適用法および紛争解決規定など。 このうち、免責条項や責任制限条項などの重要な内容は太字で記載していますので、よくお読みください。 18 歳未満の場合は、法定保護者の同伴があり、本契約をよく読んで十分に理解し、「Whistle」ソフトウェアおよび関連サービスを使用する前に法定保護者の同意を得てください。 <br>
		本契約のすべての内容に完全に同意しない限り、「Whistle」ソフトウェアをダウンロード、インストール、登録、ログイン、使用（以下、総称して「使用」といいます）、または「Whistle」サービスを使用する権利はありません。何らかの方法で、または「ホイッスル」を取得する ソフトウェアによって提供されるサービス
		(本契約では総称して「使用」と呼びます)。 「Whistle」ソフトウェアおよび関連サービスを使用する場合、本契約を完全に理解し、本契約の当事者としてそれに拘束されることを約束したものとみなされます。 <br>
		本契約の内容について質問、コメント、提案がある場合は、「Whistle」クライアントの「フィードバックとヘルプ」ページにログインして、当社にお問い合わせください。 </p>
		
		<h3>1. 適用範囲</h3>
		<p>1.1 この契約は、お客様と CLICK ADVERTISING & MEDIA PTE. との間で締結されます。
		お客様による「Whistle」ソフトウェアのダウンロード、インストール、登録、ログイン、使用、および「Whistle」ソフトウェアが提供する関連サービスの取得については、「Whistle」ソフトウェアのダウンロード、インストール、登録、ログイン、使用については、「Whistle」ソフトウェアが提供する関連サービスの取得については、「当社」といいます。 当社は、「Whistle」ソフトウェアおよび関連するニーズに基づいて、「Whistle」ソフトウェアの運用を継続するために当社が認めた関連会社、支配会社、後継会社または第三者会社を手配または指定することを一方的に決定する権利を有します。サービスまたはオペレーション。 さらに、本契約に基づく特定のサービスは、当社の関連会社、支配会社、後継会社、または当社が認めた第三者企業によってお客様に提供される場合があります。 お客様は、関連するサービス内容を承諾することにより、関連する権利と義務を承諾したものとみなされ、本契約にも拘束されることを理解し、これに同意するものとします。 <br>
		1.2 「ユーザー」とは、自然人、法人、その他の組織を含む、「Whistle」ソフトウェアおよび関連サービスを直接的または間接的に取得して使用するすべてのユーザーを指します。 本契約では「ユーザー」または「お客様」と呼ばれます。
		1.3 「Whistle」とは、「Whistle」というラベルの付いたクライアント アプリケーションおよび当社が法的に所有および運営する当社の Web サイトを指します。 当社は、アプリケーションおよびウェブサイトの名称を一方的に変更（名称の変更、追加等を含むがこれらに限定されない）する権利を有します。
		1.4 本契約の内容には、当社がこれまでにリリースし、今後リリースし続ける可能性のある「Whistle」ソフトウェアおよび関連サービスに関する関連契約、規定等（「Whistle」プライバシーポリシーを含む）も含まれます。 上記内容が正式にリリースされた場合、当社は適切な方法（ウェブサイトの発表、システム上の通知など）でその内容を読むよう注意を喚起します。 前述の関連協定および規則は本契約の不可欠な部分を構成しており、お客様はそれらにも従うものとします。
		</p>
		
		<h3>2. 「Whistle」ソフトウェアおよび関連サービスを使用する</h3>
		<p>
		2.1 「Whistle」ソフトウェアおよび関連サービスを使用する場合、プレインストールまたは当社が許可したサードパーティのダウンロードを通じて、「Whistle」クライアント アプリケーションを入手したり、Whistle 関連の Web サイトにアクセスしたりできます。 当社または当社が認めた第三者からソフトウェアを入手されなかった場合、当社は非公式版「Whistle」ソフトウェアが正常に使用できることを保証できず、お客様が何らかの損害を被ったとしても、当社は一切責任を負いません。その結果苦しみます。 <br>
		2.2 当社は、端末機器ごとに異なるバージョンのアプリケーションソフトウェアを開発する場合がありますので、実際の機器の状況に応じて、適切なバージョンを入手、ダウンロード、インストールしてください。 <br>
		2.3 お客様は、必要に応じて「Whistle」ソフトウェアおよび関連サービスを使用したり、「Whistle」バージョンをアップデートしたりすることができ、「Whistle」ソフトウェアおよび関連サービスを使用する必要がなくなった場合には、お客様ご自身でアンインストールすることができます。 <br>
		2.4 ユーザーエクスペリエンスとサービスをより向上させるために、当社はソフトウェアの更新または変更を随時提供します（ソフトウェアの修正、アップグレード、機能強化、新しいサービスの開発、ソフトウェアの置き換えなどを含みますが、これらに限定されません）。 当社は、「Whistle」ソフトウェアおよび関連サービスの安全性を確保し、ユーザーサービスを向上させるため、ソフトウェアおよび関連サービスの更新またはサービス内容の一部の更新後、可能な場合にはシステムその他の方法を使用します。ユーザーには、更新されたバージョンまたはサービスを受け入れるかどうかを選択する権利があることが通知されます。ユーザーが受け入れない場合、一部の機能が制限されるか、使用できなくなります。 <br>
		2.5 当社によって事前に明示的に許可されていない限り、お客様は、翻案、コピー、配布、垂直検索、ミラーリング、または取引を含むがこれらに限定されない、いかなる形式でも「Whistle」ソフトウェアおよび関連サービスへの不正アクセスまたは使用を行ってはなりません。 <br>
		2.6 「Whistle」ソフトウェアおよび関連サービスをご利用いただく場合、お客様は、本ソフトウェアおよび関連サービスに関連する端末機器（コンピュータ、携帯電話等）をご自身でご用意いただく必要があることを了承するものとします。お客様は、お客様の端末機器内のソフトウェアまたはWhistle関連ウェブサイトにアクセスした場合、「Whistle」ソフトウェアおよび関連サービスを利用しているものとみなされます。 「Whistle」の全ての機能を十分に発揮するためには、端末装置をインターネットに接続する必要がある場合があり、それに必要な費用（通信料、インターネット利用料等）はお客様のご負担となりますことを了承します。 <br>
		2.7 当社は、「Whistle」ソフトウェアおよび関連サービスを合法的に使用する個人的、取消可能、譲渡不可、非独占的、非商業的権利をお客様に付与します。 本契約で明示的に許可されていないその他すべての権利は、引き続き当社が留保します。これらの権利を行使する場合は、当社から追加の書面による許可を取得する必要があります。同時に、当社が前述の権利のいずれかを行使しなかったとしても、それは意味しません。かかる権利の放棄。 <br>
		2.8 「Whistle」ソフトウェアおよび関連サービスは登録なしで利用を開始できます。 同時に、お客様は、「Whistle」ソフトウェアおよび関連サービスをより効果的に使用できるようにし、アカウント、特定の機能および/またはコメント サービスなどの特定の個別サービスのセキュリティを保護するために、関連する法律および規制の規定に従い、実名登録およびログインは、実際の身元情報を提供した場合にのみ使用できます。 <br>
		2.9 「Whistle」ソフトウェアおよびWebサイトにお客様の権利を侵害するコンテンツを発見した場合は、Whistle侵害苦情窓口の規定に従って直ちに当社に通知し、お客様が関連する権利を有していることの予備的証拠を提出してください。現地の法律および規制に従ってタイムリーに提出してください。 「Whistle」で公開したコンテンツが関連する権利所有者から侵害の申し立てを受けた場合、異議申し立て通知と関連する権利証明資料をクライアントに提出することで当社に苦情を申し立てることもでき、当社はこれについても対応します。現地の法律および規制に従って、お客様の訴訟をタイムリーに処理します。
		</p>

		<h3>3.「アカウント」について</h3>
		
		<p>3.1 「Whistle」はユーザーに登録チャネルを提供します。ユーザーは、合法的な文字の組み合わせを自分のアカウントとして選択し、セキュリティ要件を満たす独自のパスワードを設定する権利を有します。 ユーザーが設定したアカウント番号とパスワードは、ユーザーが登録ユーザーとして「Whistle」ソフトウェアおよび関連サービスにログインし、利用するための認証情報となります。 </p>
		<p>3.2 <strong>アカウントのキャンセル</strong><br>
		「Whistle」アカウントのサービスの利用を終了する必要がある場合は、以下の条件を満たす場合に「Whistle」アカウントのキャンセルを申請できます。<br>
		(1) 自分のアカウントのキャンセルを申請し、「ホイッスル」プロセスに従うことのみが可能です。<br>
		(2) アカウントをキャンセルする前および「Whistle」サービスの使用中の行動については、引き続き相応の責任を負う必要があります。<br>
		(3) 退会後のアカウント記録や機能等の復元・提供はいたしません。 <br>
		「Whistle」アカウントからログアウトする必要がある場合は、Whistle クライアントを開いて、プロンプトに従ってログアウトしてください。 </p>
		<p>3.3 お客様は、設定したアカウントが国内法、規制および会社の関連規定に違反してはいけないことを理解し、約束するものとします。アカウント名、アバター、プロフィール、その他の登録情報およびその他の個人情報には、違法または有害な情報が含まれていてはなりません。他人の許可を得て、他人の名前でアカウントを開設すること（混乱を引き起こすのに十分な方法で他人の名前、名前、フォントサイズ、アバターなどを使用することを含みますがこれらに限定されません）を開くことはできません。悪意を持って「Whistle」アカウントを登録すること（頻繁な登録、一括登録アカウントおよびその他の行為を含みますがこれらに限定されません）は許可されません。 アカウントの登録および利用にあたっては、関連法令を遵守しなければならず、国益を侵害したり、他の国民の正当な権利や利益を害したり、社会道徳を損なう行為を行ってはなりません。 当社は、お客様が提出した登録情報を確認する権利を有します。 </p>
		<p>3.4 「Whistle」に登録されているアカウントは、お客様自身の使用のみを目的としており、当社の書面による同意がない限り、いかなる形式でもアカウントを譲渡、貸与、譲渡、販売、またはその他の方法で他者に使用させることは禁止されています。 。 ユーザーがアカウントの初期登録者ではないことを当社が発見した場合、またはそう信じるに足る合理的な理由がある場合、アカウントのセキュリティを保護するために、当社は、登録されたアカウントへのサービスの提供を直ちに停止または終了する権利を有します。 、またはアカウントをキャンセルしてください。 </p>
		<p>3.5 あなたは、自分の個人アカウントとパスワードのセキュリティと機密性を維持する責任を負い、登録したアカウントの名前で行う活動（ユーザーが行うデータの変更を含みますがこれに限定されません）に対する全法的責任を負います。 「ホイッスル」、音声公開、支払いおよびその他の運用行為。 アカウント番号とパスワードの機密性を非常に重視し、いかなる状況でもアカウント番号とパスワードを他人に開示しないでください。 他人があなたのアカウントを許可なく使用していることを発見した場合、またはその他のセキュリティ侵害が発生した場合は、すぐに会社に通知する必要があります。 </p>
		<p>3.6 アカウントのパスワードを紛失または忘れた場合は、会社の異議申し立てチャンネルに従って、直ちに申し立てを行い、アカウントまたはパスワードの取得をリクエストすることができます。 お客様は、会社のパスワード検索メカニズムは、苦情フォームに記入された情報がシステム記録情報と一致することを識別するだけでよく、苦情を申し立てた人がアカウントの実際の許可されたユーザーであるかどうかを識別することはできないことを理解し、了承するものとします。 同社は、アカウント番号とパスワードを適切に保管するよう特に注意しています。 使用が終了したら、安全に終了する必要があります。 不適切な保管またはその他の不可抗力要因など、お客様自身の理由により、アカウントが盗まれたり、パスワードが紛失したりする可能性があります。これについてはお客様が単独で責任を負うものとします。 </p>
		<p>3.7 アカウントを登録、使用、管理する場合は、アカウントの登録時に入力された身元情報の信頼性を確認する必要があります。登録および管理の際には、真実、正確、合法かつ有効な関連する身元証明資料および必要な書類を使用してください。アカウント情報 (お客様の名前と電子メール アドレス、連絡先番号、連絡先住所などを含みますが、これらに限定されません)。 国内法令に従い、本ソフトウェアおよびサービスの一部の機能をご利用いただくためには、本人情報の入力が必要となりますので、関連法令に従って実名認証を完了していただくとともに、最新情報の更新にご注意ください。上記の関連情報をタイムリーに提供します。 お客様が提出した資料またはお客様が提供した情報が不正確、虚偽、非標準、違法である場合、または当社がそれらが間違っている、虚偽または違法であると疑う理由がある場合、当社はお客様に関連するサービスまたはサービスの提供を拒否する権利を有します。ご利用中、「Whistle」ソフトウェアおよび関連サービスがご利用いただけなくなったり、一部の機能が制限される場合があります。 </p>
		<p>3.8 自分で「Whistle」アカウントを登録することに加えて、会社および/またはその関連会社を含むがこれらに限定されない、合法的に所有する他のソフトウェア ユーザー アカウントを使用するための認証を使用することも選択できます。実名で登録されたサードパーティ ソフトウェアまたはプラットフォーム ユーザーとしてアカウントを登録し、ログインすると、サードパーティ ソフトウェアまたはプラットフォームによって制限または禁止されている場合を除き、「Whistle」ソフトウェアおよび関連サービスを使用できます。 上記の既存のアカウントでログインする場合は、対応するアカウントが実名で登録されていることを確認し、本契約の関連規定を適用することに同意する必要があります。 </p>
		<p>3.9 お客様は、「Whistle」ソフトウェアおよび関連サービスにログインして使用することに加えて、「Whistle」アカウントを使用してログインし、当社およびその関連会社が提供する他のソフトウェアおよびサービスを使用できることを理解し、これに同意するものとします。または他のパートナー。 「Whistle」アカウントでログインして上記のサービスを利用する場合は、他のソフトウェアおよびサービスプロバイダーの「ユーザー契約」およびその他の契約条件にも拘束されるものとします。 </p>
		<p>3.10 お客様のコンテンツの露出と公開効率を向上させるために、お客様は、このソフトウェア/ウェブサイト内のお客様のアカウント、および対応するアカウントによって公開されたすべてのコンテンツが、企業および/または関連会社に自動的に公開されることを会社に許可することに同意するものとします。アカウントを使用して一連のクライアント ソフトウェアと Web サイトを運用します。 本ソフトウェア/Web サイト上のコンテンツの公開、変更、削除の操作は、上記の一連のクライアント ソフトウェアおよび Web サイトと同期されます。 <br>登録または同期されたアカウントを介して、当社および/または関連会社 (存在する場合) が運営する一連のクライアント ソフトウェア製品および Web サイトにログインする場合は、「ユーザー契約」およびその他の契約に従う必要があります。ソフトウェア製品およびウェブサイト自体の規約の条項。 </p>
		<p>3.11 他者による悪意のある攻撃、お客様自身の理由、またはその他の不可抗力によりアカウントが盗難または紛失された場合、その責任はお客様が単独で負うものとし、当社は一切の責任を負いません。 </p>
		<p>3.12 お客様は、アカウント リソースを最大限に活用するために、登録後初めてログインに失敗した場合、またはアイドル状態のアカウントに連続 2 か月以上ログインしなかった場合、会社が次の措置を講じることを理解するものとします。いつでもアカウントを取り消す権利。 アカウントが取り消されると、以前に保有していたアカウントを介して「Whistle」ソフトウェアおよび関連サービスにログインして使用することができなくなり、アカウントに保存された個人設定や使用記録も復元されなくなります。 </p>
		<p>アカウントを退会する前に、当社は適切な方法で通知を行います。当該通知が表示されてから一定期間内にログインおよびアカウントの使用が行われなかった場合、当社はアカウントを退会させていただきます。 </p>
		<p>アカウントが退会された場合は、新しいアカウントを登録することで、「Whistle」ソフトウェアおよび関連サービスにログインしてご利用いただけます。 新しいアカウントの登録、ログイン、および使用という行為には、引き続き本契約の関連条件が適用されます。 </p>
		
		<h3>4. ユーザーの個人情報の保護</h3>

		<p>4.1 当社はユーザー情報の保護を非常に重視しており、ユーザーの個人情報を保護することは当社の基本原則の 1 つです。 当社は、ユーザーの個人情報（単独で、または他の情報と組み合わせてユーザーを識別できる情報）の保護にユーザーとともに努めます。 </p>
		<p>4.2
		「Whistle」ソフトウェアおよび関連サービスを使用する過程で、当社がお客様に提供する目的でお客様の個人情報（氏名、電話番号、位置情報、デバイス情報などを含みますがこれらに限定されません）を提供する必要がある場合があります。より良いサービスとそれに対応する技術サポート。 お客様は、当社が法令、本規約、および「Whistle」プライバシーポリシーを遵守することを前提として、お客様の個人情報を収集、使用（商業協力利用を含む）、保管、共有する権利を有していることを理解し、これに同意するものとします。関連する個人情報を閲覧、変更、削除し、同意を撤回する権利を保護します。
		</p>
		<p>4.3 お客様は、送信した個人を特定できる情報をいつでも閲覧および変更できます。お客様は、セキュリティおよび識別上の理由 (アカウントまたはパスワードの取得および異議申し立てサービスなど) により、これらの情報を閲覧および変更できない場合があることを理解し、これに同意するものとします。登録時に提供された情報の変更、初期登録情報およびその他の確認情報。 </p>
		<p>4.4 当社は、「Whistle」ソフトウェアおよび関連サービスに適合したセキュリティ技術およびその他のセキュリティ対策を利用し、お客様の個人情報を保護し、不正または無断使用を防止するために万全の管理体制を確立します。使用または開示。 </p>
		<p>4.5 当社は、以下の場合を除き、お客様の個人情報をお客様の同意なしに社外の企業、組織、個人に開示することはありません。</p>
		<p>
		(1) 事前に明示的な許可と同意を得てください。<br>
		(2) お客様がご自身で他の企業、組織、個人に共有、譲渡、開示する場合。<br>
		(3) 国家安全保障、国防安全保障、公共の安全、公衆衛生、公共の利益に直接関連するもの。<br>
		(4) 適用される法令の要求、行政および司法上の強制的要求に従って、または犯罪捜査、起訴、裁判および判決の執行などに直接関連する場合、必要な場合の開示または提供。 当社は、法令を遵守することを前提として、上記の情報開示の求めを受けた場合には、相手方に対し召喚状や調査状などの相応の法的文書の発行を求めます。 当社は、すべてのリクエストを慎重に検討して法的根拠があることを確認し、行政部門と司法部門が特定の調査目的で取得する法的権利を有するデータに限定されるものとします。<br>
		(5) 法令で認められる範囲内で、他の「Whistle」利用者、当社およびその関連会社の正当な権利利益の維持、当社の生命、財産等の管理または安全安定な管理のために必要がある場合検索などの権利保護製品またはサービスの運用、詐欺などの違法行為を防止および対処し、信用リスクを軽減する（本契約での約束への違反および営利目的の個人情報の開示または提供を除く）。<br>
		(6) 法律報道、政府情報開示、その他のルートなど、法的に公開された情報からの個人情報。<br>
		(7) その他法令で定める場合。 <br>
		</p>
		<p>4.6 「Whistle」ソフトウェアおよび関連サービスを使用する際は、さまざまな財産口座、銀行カード、クレジット カード、サードパーティの支払い口座、および対応するパスワードやその他の重要な情報を開示しないように注意してください。開示しない場合、損失が発生する可能性があります。それはあなた自身の責任です。 </p>
		<p>4.7 「Whistle」ソフトウェアおよび関連サービスには、第三者が提供する情報または他のサービス (Web サイトを含む) が含まれているか、それらにリンクされている可能性があることを考慮してください。 お客様は、かかるサービスを運営する第三者がお客様に個人情報の提供を求める場合があることを承知し、理解しています。 当社は、これらの第三者のユーザー契約、プライバシーポリシー、およびその他の関連条件を注意深く読み、個人情報を適切に保護し、必要な場合にのみこれらの第三者に提供する必要があることを特に注意します。 本契約 (および「Whistle」ソフトウェアおよび関連サービス (「Whistle」プライバシー ポリシーを含むがこれに限定されない) に関連するその他の契約および規則) は、第三者が提供するサービスには適用されません。当社はいかなる法的責任も負いません。提供された個人情報から生じる可能性のある結果については、責任を負いません。<br>ユーザーの個人情報の保護の詳細については、「Whistle」プライバシー ポリシーをご覧ください。</p>
				
		<h2>5. ユーザーの行動規範</h2>
				
		<h3>5.1 ユーザーの行動要件</h3>
		<p>お客様は、この製品および関連サービスの使用について責任を負います。法律で許可されている場合または当社の事前の書面による許可がない限り、「Whistle」ソフトウェアおよび関連サービスを使用する際には、次の行為を行ってはなりません。</p>
			
		<p>5.1.1 当社が認可または許可していないプラグイン、プラグイン、システム、またはサードパーティ ツールを使用して、「Whistle」ソフトウェアの通常の動作を妨害、破壊、改変、またはその他の影響を与えること。関連サービス。 </p>
			
		<p>5.1.2 「Whistle」ソフトウェアおよび関連サービスを使用またはターゲットにして、以下を含むがこれらに限定されない、コンピュータ ネットワークのセキュリティを危険にさらす行為を行うこと。</p>
		<ul>
		<li>（1）他人のネットワークへの違法な侵入、他人のネットワークの通常の機能への干渉、ネットワーク データの盗難、およびネットワーク セキュリティを危険にさらすその他の行為</li>
		<li>（2）ネットワークへの侵入、通常のネットワーク機能や保護手段の妨害、ネットワーク データの窃取など、ネットワーク セキュリティを危険にさらす行為に従事するために特別に使用されるプログラムやツールを提供する。</li>
		<li>（3）他者がネットワーク セキュリティを危険にさらす活動に従事していることを知り、技術サポート、広告宣伝、支払いと決済などを提供する。</li>
		<li>（4）未承認のデータを使用するか、未承認のサーバー/アカウントにアクセスする。</li>
		<li>（5）許可なく公共のコンピュータ ネットワークまたは他人のコンピュータ システムに侵入し、保存されている情報を削除、変更、または追加する</li>
		<li>（6）許可なく「Whistle」システムまたはネットワークの弱点を調査、スキャン、テストしようとしたり、ネットワーク セキュリティを損なうその他の行為を実行したりすること。</li>
		<li>（7）「Whistle」システムまたはウェブサイトの通常の動作を妨害または妨害しようとすること、悪意のあるプログラムやウイルスを意図的に拡散すること、および通常のネットワーク情報サービスを妨害または妨害するその他の行為</li>
		<li>(8) TCP/IP パケット名または名前の一部を偽造します。 </li>
		</ul>
			
		<p>5.1.3 リバース エンジニアリング、逆アセンブル、コンパイル、またはその他の方法で「Whistle」ソフトウェアのソース コードの発見を試みます。 </p>
			
		<p>5.1.4 「Whistle」アカウントの悪意のある登録。アカウントの頻繁な登録や一括登録が含まれますが、これに限定されません。 </p>
			
		<p>5.1.5 法律、規制、本契約、会社の関連規定に違反し、その他他者の正当な権利や利益を侵害する行為。 </p>
			
		<p>いずれの場合も、お客様の行為が上記規約に違反している、または違反する可能性があると当社が信じる理由がある場合、当社は独自に判断して対処することができ、いつでもユーザーへのサービスを終了する権利を有します。サービスを提供し、サービスに責任を負わせます。 </p>
			
		<h3>5.2 情報コンテンツの仕様</h3>
		<p>5.2.1 ユーザーは、必要に応じて実名認証を完了した後、登録したアカウントまたは「Whistle」連携プラットフォームのアカウントで「Whistle」にログインし、コンテンツの公開やコメントの投稿などを行うことができます。 </p>
			
		<p>5.2.2 当社は、コメントが文明的で合理的、友好的で質の高い意見交換となるよう努めています。 当社は、コメントビジネスの発展を推進するとともに、引き続き、それに対応する情報セキュリティ管理能力を強化し、コメントの自制心を向上させ、社会的責任を効果的に果たし、国内法令を遵守し、国民の正当な権利と利益を尊重し、社会秩序と良き習慣を尊重します。 </p>
			
		<p>5.2.3 ユーザーが作成、コメント、公開、配布するコンテンツ (「Whistle」プラットフォームにアップロードされた未公開のプライベートビデオ、および公開後 72 時間の表示期間を持つスナップショットを含みますがこれらに限定されません) は、意識的に遵守する必要があります。法令、社会主義制度、国益、国民の正当な権利と利益、社会公序良俗、道徳と情報の信頼性などの「7つのボトムライン」要件に従わない場合、会社は直ちに対応する措置を講じます。 ユーザーは次の情報を投稿することはできません:</p>
		<ul>
		<li>（1）憲法で定められた基本原則に反対する。</li>
		<li>（2）国家安全保障を危険にさらし、国家機密を漏洩する。</li>
		<li>（3）国家権力の転覆、社会主義制度の転覆、国の分裂を扇動し、国家の統一を損なうこと</li>
		<li>（4）国家の名誉と利益を損なうこと</li>
		<li>(5) テロリズムと過激主義の促進</li>
		<li>（6）民族的憎悪と差別を助長し、民族的団結を損なうこと</li>
		<li>(7) 地域的な差別と憎悪を扇動するもの。</li>
		<li>（8）国の宗教政策を損ない、カルトや迷信を促進する</li>
		<li>（9）社会秩序を混乱させ、社会の安定を損なう目的で、噂や虚偽の情報をでっちあげ、広めること</li>
		<li>（10）わいせつ、ポルノ、ギャンブル、暴力、殺人、テロ、または犯罪を扇動する行為の拡散または流布</li>
		<li>（11）ネットワークのセキュリティを危険にさらすこと、または国家の安全、名誉、利益を危険にさらすためにインターネットを使用すること</li>
		<li>（12）他者を侮辱または中傷し、他者の正当な権利および利益を侵害すること。</li>
		<li>（13）暴力による威嚇や脅迫、人体検査の実施</li>
		<li>(14) 他人のプライバシー、個人情報、またはデータに関与すること。</li>
		<li>(15) 汚い言葉を広め、社会秩序や善良な道徳を損なう</li>
		<li>(16) 他人のプライバシー、評判、肖像、知的財産、その他の正当な権利や利益を侵害するコンテンツ</li>
		<li>(17) 商業広告や類似の商業勧誘情報、過剰なマーケティング情報、スパム情報を広めること。</li>
		<li>(18) このウェブサイトで一般的に使用されている言語以外の言語でのコメント</li>
		<li>(19) コメントされている情報とは何の関係もありません。</li>
		<li>(20) 公開された情報が無意味であるか、技術的なレビューを回避するために文字の組み合わせが意図的に使用されている。</li>
		<li>(21) 未成年者の正当な権利および利益を侵害すること、または未成年者の身体的および精神的健康を害すること。</li>
		<li>（22）許可なく他人を密かに写真撮影または録音し、他人の法的権利を侵害する</li>
		<li>(23) ホラー、暴力、流血、高リスクを含むコンテンツ、または出演者や他者の身体的および精神的健康を危険にさらすコンテンツ。これには次の状況が含まれますが、これらに限定されません。</li>
		<ul>
		<li>1) 暴力的なコンテンツや自傷行為を伴うコンテンツ</li>
		<li>2) 生命や健康を脅かすコンテンツ、またはナイフやその他の危険な器具を使用して自分自身や他人の個人的権利や財産権を危険にさらす内容</li>
		<li>3) 人身傷害や死亡を引き起こす可能性のある危険または違法な活動への他人の参加を奨励または誘導するコンテンツ。 </li>
		</ul>
		<li>(24) その他、法令、政策、公序良俗に違反し、「Whistle」の正常な運営を妨げ、または他の利用者もしくは第三者の正当な権利利益を侵害する情報。 </li>
		</ul>
				
		<h2>6. 「Whistle」データ使用仕様</h2>
		
		<p>6.1 当社の書面による許可がない限り、ユーザーは、本契約の「Whistle」ソフトウェアおよび関連サービスの情報コンテンツに対して以下の行為を行うことを、自らまたは許可して、第三者に許可または援助してはなりません。 </p>
		<ul>
		<li>（1）「Whistle」ソフトウェアおよび関連サービスの情報コンテンツを、プロモーション、閲覧数の増加、ページビューの増加などを含むがこれらに限定されない商業目的でコピー、読み取り、使用すること。</li>
		<li>（2）許可なく「Whistle」ソフトウェアおよび関連サービスの情報内容を編集、整理、整理し、Whistle ソフトウェアおよび関連サービスのソース ページ以外のチャネルに表示すること。</li>
		<li>(3) トラフィックの生成、読み取り量、ガイド、転送、または「Whistle」ソフトウェアの情報やコンテンツを転送するために、特別なロゴ、特別なコードなどを含むがこれらに限定されない、あらゆる形式の識別方法を使用すること。関連サービスを独自に、または第三者を支援して利用するハイジャックおよびその他の悪影響</li>
		<li>(4) その他、「Whistle」ソフトウェアおよび関連サービスの情報コンテンツを不正に入手する行為。 </li>
		</ul>
			
		<p>6.2 会社の書面による許可があれば、ユーザーによる「Whistle」ソフトウェアおよび関連サービスの情報およびコンテンツの共有および転送も、次の仕様に準拠する必要があります。</p>
		<ul>
		<li>(1) クローリング、カウント、取得により得られたホット検索ワード、ヒット率、分類、検索量、クリック率、閲覧量などの関連データは、当社の事前の書面による同意がない限り、いかなる形でも使用しないものとします。 . 第三者に公開、提供、または開示する</li>
		<li>(2) 「Whistle」ソフトウェアおよび関連サービスのホームページ リンク入口を含むがこれに限定されない、「Whistle」ソフトウェアおよび関連サービスのソース Web ページにいかなる形式の変更も加えてはなりません。 「Whistle」ソフトウェアおよび関連サービスに変更を加えることはできません。サービスのソース ページの表示は、ブロック、挿入、ポップアップ ウィンドウなどによって、いかなる形でも妨げられないものとします。</li>
		<li>(3) 「Whistle」ソフトウェアおよび関連サービスの情報コンテンツが、「スパイダー」プログラムを含むがこれに限定されない、あらゆる形式を通じて第三者によって違法に取得されることを防ぐために、安全、効果的、厳格な措置を講じる必要があります。 ;</li>
		<li>(4) 関連するデータ コンテンツは、会社の書面による許可の範囲を超えた目的で使用したり、いかなる形式の販売や商業利用を行ったり、いかなる方法であっても第三者に開示、提供、または使用を許可したりしてはなりません。リ>リ>
		<li>(5) 「Whistle」ソフトウェアおよび関連サービス情報コンテンツを第三者に共有、転送、コピーするユーザーの行為は、コミュニティ自主規律条約など、当社が定めた他の規範および基準にも準拠するものとします。および「ホイッスル」他の機能の関連プロトコル。 </li>
		</ul>
			
		<h2>7. 違反への対応</h2>
				
		<p>7.1 お客様が本規約またはその他の利用規約に違反した場合、当社は独自に判断し、事前に警告し、公開を拒否し、情報の送信を直ちに停止し、投稿、音声、ビデオおよびその他のコンテンツを削除する権利を有します。短期間の発言禁止、アカウントの一部または全部の機能の制限、またはアカウントの永久閉鎖などの措置。 当社は、処理結果を発表し、実際の状況に基づいて使用を再開するかどうかを決定する権利を有します。 法令違反の疑いや違法犯罪の疑いについては、関連記録を保管し、法令に基づき所轄官庁に報告するとともに、所轄官庁の捜査に協力します。 当社は、削除されたコンテンツを返却しない権利を留保します。 </p>
		<p>7.2 お客様による本契約またはその他の利用規約の違反が原因で第三者から苦情または申し立てがなされた場合、お客様はすべての法的責任を負うものとします。 会社およびその関連会社、支配会社、および後継会社が第三者に対して補償を行った場合、またはあなたの違法または契約違反により国家機関から処罰された場合、あなたは会社およびその関連会社、支配会社、および後継会社に対しても全額補償するものとします。損失総額の結果として被った損失については、企業に賠償するものとします。 </p>
		<p>7.3 当社は、法人および国民の知的財産権、評判の権利、名前の権利、プライバシーの権利、およびその他の正当な権利と利益を尊重し、保護します。 お客様は、「Whistle」ソフトウェアおよび関連サービスの使用時にアップロードされるテキスト、ビデオ、音声、リンク等が第三者の知的財産権、名誉権、氏名権、プライバシー権およびその他の権利および正当な権利を侵害しないことを保証するものとします。興味のあること。 それ以外の場合、当社は権利所有者または関連当事者から通知を受け取った時点で、侵害の疑いのあるコンテンツを削除する権利を有します。 あなたは、第三者によるすべての請求に対してすべての法的責任を負うものとし、あなたの権利侵害により当社およびその関連会社、支配会社、および後継会社が損失（経済的損失、営業権などの損失を含むがこれらに限定されない）を被った場合、あなたは、次のことを行う必要があります。また、当社およびその関連会社、支配会社、および後継会社が被ったすべての損失を全額補償します。 </p>
		<h2>8. サービスの変更、中断、終了</h2>
		<p>8.1 お客様は、当社が提供する「Whistle」ソフトウェアおよび関連サービスが、既存の技術および条件が達成できる現状に従って提供されることを理解し、これに同意するものとします。 当社は、お客様にサービスを提供し、サービスの継続性とセキュリティを確保するために最善を尽くします。 お客様は、当社が、不可抗力、ウイルス、トロイの木馬、ハッカー攻撃、システムの不安定性、サードパーティのサービスの欠陥、およびさまざまなセキュリティ問題によるその他の侵入を含むがこれらに限定されない、法的、技術的およびその他のリスクをいつでも予見および防止することはできないことを理解するものとします。サービスの中断、データの損失、その他の損失とリスク。 </p>
		<p>8.2 お客様は、サービス運営全体のニーズを満たすために、当社が発表および通知後に「Whistle」ソフトウェアおよび関連サービスを変更、中断、一時停止、または終了する権利を有することを理解し、これに同意するものとします。ユーザーに対していかなる補償についても責任を負うことはありません。 </p>
		<h2>9. 広告</h2>
		<p>9.1 「Whistle」ソフトウェアおよび関連サービスを使用する場合、お客様は次のことを十分に理解し、同意するものとします。このサービスには、当社が個人または企業向けに開始する情報、広告、またはブランド プロモーション サービスが含まれる場合があります。また、当社が以下のことを行うことに同意するものとします。 「Whistle」「Whistle」ソフトウェアおよび関連サービスを使用する権利、および/またはサードパーティのサプライヤーおよびパートナーがソフトウェアおよび関連サービスに商業広告、プロモーション、または情報（商業情報または非商業情報を含む）を表示する権利。 </p>
		<p>9.2 広告に同意できない場合、お客様は広告情報をオフにすることを選択する権利を有します。「Whistle」プッシュ通知サービスについては、お客様は自らサービスをオフにするか、「」の使用を停止する権利を有します。 Whistle」ソフトウェアおよび関連サービス。 </p>
		<p>9.3 当社は、法律の規定に従って広告およびプロモーションに関する義務を履行します。広告またはプロモーション情報の信頼性および信頼性は、お客様ご自身で判断し、ご自身の判断に責任を負うものとします。 法令により明示的に定められている場合を除き、当該広告・宣伝情報に基づいて行われた購入・取引、または上記内容に起因して被った損害・損失については、利用者が単独で責任を負うものとし、当社は一切の責任を負いません。 </p>
			
		<h2>10. 知的財産権</h2>
		<p>10.1 当社が「ホイッスル」で提供するコンテンツ（ソフトウェア、テクノロジー、プログラム、ウェブページ、テキスト、写真、画像、音声、ビデオ、チャート、レイアウトデザイン、電子文書などを含みますが、これらに限定されません） 「ソフトウェアおよび関連サービス」の知的財産権は当社に帰属します。 当社が本サービスを提供するソフトウェアの著作権、特許権その他の知的財産権は当社が所有します。 当社の許可なく、これを無断で使用することはできません（ロボット、スパイダーを介した「Whistle」ソフトウェアおよび関連サービスのコンテンツの監視、コピー、配布、表示、ミラーリング、アップロード、ダウンロードを含みますがこれらに限定されません）。 、などのプログラムまたはデバイス）。 </p>
		<p>10.2 お客様は、「Whistle」ソフトウェアおよび関連サービスの使用時に投稿およびアップロードされるテキスト、写真、ビデオ、音声などはすべてお客様のオリジナルであるか、法的に許可されているもの（サブライセンスを含む）であることを理解し、これに同意するものとします。 「Whistle」を通じてアップロードまたは公開したコンテンツの知的財産権は、あなたまたは元の著作権所有者に帰属します。 </p>
		<p>10.3 あなたは、「Whistle」を通じて公開およびアップロードしたコンテンツ (ビデオおよび/またはその両方に含まれるテキスト、画像、ビデオ、オーディオ、音楽作品、サウンド、ダイアログなどを含みますがこれらに限定されません) を理解し、同意します。当社およびその関連会社、支配会社、および後継会社は、世界中で無料で非独占的でサブライセンス可能な（複数のレベルによる）権利（複製権、翻訳権、アセンブリ権、および情報を含むがこれらに限定されない）を有します。使用範囲には、現在または他の Web サイト、アプリケーション、製品、端末デバイスなどが含まれますが、これらに限定されません。 お客様は、前述の権利の付与には、当社コンテンツ「ホイッスル」に関連するプロモーション、広告、マーケティングおよび/または宣伝におけるコンテンツの使用およびその他の活用（全部または一部）が含まれることをここに認め、これに同意するものとします。および/または当社のブランド）の権利およびライセンス。 誤解を避けるために、お客様は、上記の権利の付与には、お客様の個人的な画像、肖像、名前、商標、サービス マーク、ブランド、名前、ロゴ、および会社マーク (存在する場合) の使用、複製、および表示が含まれることに同意するものとします。その他のブランディング、マーケティング、またはプロモーション資産。 </p>
		<p>10.4 お客様は、会社が自社の名前を使用すること、またはアップロードおよび公開されたお客様の知的財産権を侵害するコンテンツに対してお客様に代わって権利を防御することを専門の第三者に委託することを承認することを確認および同意します。権利保護フォームには次のものが含まれますが、これらには含まれません。侵害の監視、権利保護レターの送付に限定されます。訴訟、仲裁、調停、和解などを提起する場合、会社は権利保護事項について決定を下し、独立して実行する権利を有します。 </p>
		<p>10.5 当社は、「Whistle」の開発および運用に対する技術サポートを提供し、「Whistle」ソフトウェアおよび関連サービスの開発および運用中に生成されるすべてのデータおよび情報に対するすべての権利を有します。 </p>
		<p>10.6 当社の商標、サービスマーク、商号、ドメイン名、Web サイト名、またはその他の独特のブランド特徴 (以下、総称して「Whistle」といいます) を、いかなる状況においても許可なく使用しないでください。ロゴ」)。 当社の事前の書面による同意がない限り、お客様は、いかなる方法であっても、本規約の単独または前述のロゴと組み合わせて、登録商標、ドメイン名登録などを表示、使用、または申請することはできません。また、他者に明示または暗示することもできません。これらの標識を表示、使用、またはその他の方法で処理する権利。 お客様が本規約に違反して当社の上記商標、ロゴ等を使用し、当社または他者に損害を与えた場合、お客様は一切の法的責任を負うものとします。 </p>
				
		<h2>11. 免責事項</h2>
		<p>11.1 お客様は、「Whistle」ソフトウェアおよび関連サービスがさまざまな要因によって影響または妨害される可能性があることを理解し、これに同意するものとし、当社は以下を保証しません（以下を含みますがこれに限定されません）。</p>
		<p>11.1.1 同社はユーザーの要件に完全に適合しています。</p>
		<p>11.1.2 当社は、中断がなく、タイムリーで、安全で、信頼性があり、エラーが発生しないものとし、ユーザーが当社を通じて取得したソフトウェア、サービス、またはその他のマテリアルはユーザーの期待に応えます。</p>
		<p>11.1.3 ソフトウェア内のエラーはすべて修正されます。 </p>
		<p>11.2 ユーザーは、お金を借りている疑いのあるオンライン情報、アカウントのパスワード、広告やプロモーション、その他の財産関連の情報については、慎重に取り扱い、ご自身の判断で行う必要があります。前述の理由に基づいて、利益の損失が発生する可能性があります。ビジネス上の評判、データまたはその他の情報当社は、有形または無形の損失に対する直接的、間接的、偶発的、特別、結果的または懲罰的損害に対して責任を負いません。 </p>
		<p>11.3 ユーザーは、「Whistle」ソフトウェアおよび関連サービスを使用する過程で、不可抗力などの要因に遭遇する可能性があることを理解し、これに同意するものとします（不可抗力とは、予見できない、克服できない、および回避できない客観的な出来事を指します）。これには、政府の行動、自然災害、サイバー上の理由、ハッカー攻撃、戦争、またはその他の同様の出来事が含まれますが、これらに限定されません。 不可抗力が発生した場合、当社は速やかな修復に努めますが、当該不可抗力により利用者に損害が生じた場合、利用者は当社が責任を負わないことに同意するものとします。 </p>
		<p>11.4 会社は、本契約に従って違法コンテンツに対処する権利を有します。この権利は会社の義務や約束を構成するものではありません。会社は、違法行為が適時に発見され、それに応じて対処されることを保証できません。 </p>
		<p>11.5 ユーザーは、当社が本契約に基づくサービスに関して、商業的商品性、特定の目的への適合性などを含むがこれらに限定されない、いかなる種類の保証や条件も明示的または黙示的に提供しないことを明確に理解し、これに同意するものとします。 本契約に基づくソフトウェアおよび関連サービスの使用は、お客様ご自身の責任で行われなければなりません。 </p>
		<p>11.6 ユーザーは、本規約が国内法令の遵守、公序良俗の維持、および他者の正当な権利と利益の保護を目的とするものであることを明確に理解し、これに同意するものとします。これらは、当社の判断を保証するものではなく、司法・行政機関の判断と完全に一致するものであり、ユーザーはその結果を理解し、これを負担することに同意するものとします。 </p>
				
		<h2>12. 個別サービスに関する特約</h2>
		<p>12.1 「Whistle」ソフトウェアおよび関連サービスには、当社がさまざまな法的方法で取得した情報または情報コンテンツへのリンクが含まれており、また当社およびその関連会社が合法的に運営するその他の個別サービスも含まれます。 これらのサービスは、「Whistle」上の別のセクションとして存在する場合があります。 当社は、これらの特別セクションの設定およびサービスを随時追加、削減、または変更する権利を留保します。 </p>
		<p>12.2 「Whistle」ソフトウェアでは、上記の個別のサービス機能を有効にして使用できます。 特定の個別サービスでは、そのサービスに関して特別に策定された契約、またはお客様とサービスプロバイダーの間の関係を規定するその他の規則に同意する必要がある場合があります。 当社は、これらの契約および規則を、お客様が確認できるように目立つ方法で提供します。 上記サービスの利用を開始すると、各サービスに関する関連規約や規定に拘束されるものとみなされます。 </p>
		<p>12.3 「Whistle」ソフトウェアで第三者が提供するソフトウェアおよび関連サービスを使用する場合は、本契約および「Whistle」ソフトウェアのその他の関連規則に従うことに加えて、第三者の規定にも従う必要があります。契約および関連規則。 サードパーティのソフトウェアおよび関連サービスに起因する紛争、損失、損害は、お客様とサードパーティによって解決されるものとします。 </p>
				
		<h2>13. 未成年者向けの利用規約</h2>
		<p>13.1 18 歳未満の未成年者の場合は、「Whistle」ソフトウェアおよび関連サービスを使用する前に、親またはその他の保護者の監督と指導の下で本契約を読み、同意する必要があります。 </p>
		<p>13.2 当社は、未成年者の個人情報の保護を非常に重視しています。未成年者が個人情報を入力する場合は、個人保護の意識を高め、慎重に取り扱うとともに、「Whistle」ソフトウェアおよび関連サービスを正しくご利用ください。保護者の指導。 </p>
		<p>13.3 未成年ユーザーは、法律、規制、および本契約の内容に違反した場合、法規定に従って、そこから生じるすべての結果をあなたとその保護者が負担することを理解するものとします。 </p>
		<p>13.4 未成年ユーザー向けの特別なヒント</p>
		<p>13.4.1 「Whistle」ソフトウェアおよび関連サービスを使用する青少年は、保護者の監督と指導の下、合理的な範囲でインターネットを正しく使用することを学び、仮想サイバースペースへの中毒を避け、インターネットの良好な習慣を身に付ける必要があります。 </p>
		<p>13.4.2 10 代のユーザーは、「全国青少年インターネット文明条約」を遵守する必要があります。</p>
		<ul>
		<li>(1) オンライン学習に優れ、悪い情報の閲覧は避けてください。</li>
		       <li>(2) 正直かつ友好的にコミュニケーションし、他人を侮辱したり欺いたりしないでください。</li>
		       <li>(3) 自己防衛の意識を高め、ネチズンとの安易なデートを避ける</li>
		       <li>（4）ネットワークのセキュリティを維持し、ネットワークの秩序を乱さないこと。</li>
		<li>(5) 身体的および精神的健康に有益である必要があり、仮想の時間と空間に耽溺するものではありません。 </li>
		</ul>
		<p>13.4.3 未成年者のプライバシー権をより適切に保護するために、同社は未成年者のコンテンツを含むコンテンツを公開する際には注意するようユーザーに注意を喚起しています。公開後、ユーザーは未成年者を表示する「ホイッスル」に同意したものとみなされます。 」の情報、肖像画、音声などを含み、「Whistle」が本契約に従って未成年者に関連するそのようなコンテンツを使用および処理することを許可します。 </p>
				
		<h2>14. その他</h2>
		<p>14.1 本契約の成立、有効性、履行、解釈および紛争の解決は、中華人民共和国本土の法律に準拠するものとします。 本契約のいずれかの条項が中華人民共和国本土の法律に抵触するために無効である場合、これらの条項はできる限り本契約の本来の意味に近づけて再解釈され、本契約の他の条項は引き続き存続するものとします。全力と効果。 </p>
		<p>14.2 この契約の署名場所は、中華人民共和国北京市海淀区です。お客様と当社との間に紛争が生じた場合、双方は友好的な交渉を通じて解決するよう最善を尽くす必要があります。交渉が失敗した場合は、 、あなたは、紛争が北京市海淀区の人民法院の管轄に提出されることに同意するものとします。 </p>
		<p>14.3 当社は、国の政策、技術的条件、製品機能などの変化に基づいて本契約を変更する権利を有し、当社は改訂後の契約を公開します。 上記の内容が正式にリリースされると、当社は、お客様が本契約の最新バージョンを常に把握できるように、適切な方法 (公式 Web サイトの発表、システム通知など) で更新された内容を通知します。 改訂された内容は本契約の不可欠な部分を形成し、お客様もそれに従うものとします。 改訂後の規約に異議がある場合は、直ちに「Whistle」ソフトウェアおよび関連サービスへのログインおよびご利用を中止していただき、ログインまたは継続して「Whistle」ソフトウェアおよび関連サービスをご利用いただいた場合には、同意したものとみなされます。改定された協定とともに。 </p>
		<p>14.4 本契約の見出しは便宜上、読み上げ専用であり、本契約の条項の意味や解釈には影響しません。 </p>
		<p>14.5 お客様と当社は独立した事業体です。いかなる状況においても、本契約は、当社によるユーザーに対するいかなる形式の明示的または黙示的な保証または条件を構成するものではなく、また、お客様と当社との間に代理店、パートナーシップ、合弁事業または雇用関係が存在するものではありません。両当事者。 </p>
		<p>14.6 本契約の著作権は当社に帰属し、当社は法律で認められる範囲内で解釈および変更する権利を留保します。 </p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/UserServiceAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/UserServiceAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/UserServiceAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/UserServiceAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/UserServiceAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/UserServiceAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>